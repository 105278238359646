import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * Italic 아이콘
 * @param props
 * @returns
 */
export function Italic(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
        d="M5.248 20.246H9.05m0 0h3.696m-3.696 0 5.893-16.502m0 0h-3.697m3.697 0h3.803"
      />
    </IconContainer>
  );
}

export default Italic;
