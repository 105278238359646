import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * 용량 상세 설정 아이콘
 * @param props
 * @returns
 */
export function StorageSettingsIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        d="M7.71596 6.09401C11.6214 6.09401 14.7874 4.87366 14.7874 3.36829C14.7874 1.86293 11.6214 0.642578 7.71596 0.642578C3.81051 0.642578 0.644531 1.86293 0.644531 3.36829C0.644531 4.87366 3.81051 6.09401 7.71596 6.09401Z"
        stroke="#383838"
        strokeWidth="1.28571"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7891 6.42816V3.36816"
        stroke="#383838"
        strokeWidth="1.28571"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.644531 3.36816V12.0596C0.644531 13.4366 3.27809 14.5315 6.7014 14.7483"
        stroke="#383838"
        strokeWidth="1.28571"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.644531 7.71387C0.644531 9.11065 3.41584 10.2636 6.89984 10.38"
        stroke="#383838"
        strokeWidth="1.28571"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4609 8.35645V10.285"
        stroke="#383838"
        strokeWidth="1.28571"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.57031 10.6064L11.2417 11.5707"
        stroke="#383838"
        strokeWidth="1.28571"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.57031 15.1069L11.2417 14.1426"
        stroke="#383838"
        strokeWidth="1.28571"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4609 17.3573V15.4287"
        stroke="#383838"
        strokeWidth="1.28571"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.3589 15.1069L15.6875 14.1426"
        stroke="#383838"
        strokeWidth="1.28571"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.3589 10.6064L15.6875 11.5707"
        stroke="#383838"
        strokeWidth="1.28571"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4621 15.428C14.8822 15.428 16.0335 14.2768 16.0335 12.8566C16.0335 11.4364 14.8822 10.2852 13.4621 10.2852C12.0419 10.2852 10.8906 11.4364 10.8906 12.8566C10.8906 14.2768 12.0419 15.428 13.4621 15.428Z"
        stroke="#383838"
        strokeWidth="1.28571"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconContainer>
  );
}

export default StorageSettingsIcon;
