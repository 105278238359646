import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * Bold 아이콘
 * @param props
 * @returns
 */
export function BoldIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        stroke="currentColor"
        d="M5.246 3.744a.75.75 0 0 1 .75-.75h7.125a4.875 4.875 0 0 1 3.346 8.422 5.25 5.25 0 0 1-2.97 9.58h-7.5a.75.75 0 0 1-.75-.75V3.744Zm7.125 6.75a2.625 2.625 0 0 0 0-5.25H8.246v5.25h4.125Zm-4.125 2.251v6h4.5a3 3 0 0 0 0-6h-4.5Z"
      />
    </IconContainer>
  );
}

export default BoldIcon;
