import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * 이관 아이콘
 * @param props
 * @returns
 */
export function RestoreIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        d="M8 4V8.57143L10.9714 10.0571"
        stroke="black"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2287 9.71415C14.4548 12.9904 11.5116 15.4284 7.99888 15.4284C3.8962 15.4284 0.570312 12.1026 0.570312 7.99986C0.570312 3.89717 3.8962 0.571289 7.99888 0.571289C10.8649 0.571289 13.3518 2.19427 14.5907 4.57129"
        stroke="black"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M15.4275 2.28516V5.1423H12.5703"
        stroke="black"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconContainer>
  );
}

export default RestoreIcon;
