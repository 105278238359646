import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * 오픈북 아이콘
 * @param props
 * @returns
 */
export function SettingsIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        d="M9.59399 3.94C9.68399 3.398 10.154 3 10.704 3H13.297C13.847 3 14.317 3.398 14.407 3.94L14.62 5.221C14.683 5.595 14.933 5.907 15.265 6.091C15.339 6.131 15.412 6.174 15.485 6.218C15.809 6.414 16.205 6.475 16.56 6.342L17.777 5.886C18.0264 5.79221 18.301 5.78998 18.5519 5.87971C18.8028 5.96945 19.0136 6.14531 19.147 6.376L20.443 8.623C20.576 8.8537 20.6229 9.12413 20.5753 9.38617C20.5277 9.6482 20.3887 9.88485 20.183 10.054L19.18 10.881C18.887 11.121 18.742 11.494 18.749 11.873C18.7506 11.958 18.7506 12.043 18.749 12.128C18.742 12.506 18.887 12.878 19.179 13.118L20.184 13.946C20.608 14.296 20.718 14.9 20.444 15.376L19.146 17.623C19.0128 17.8536 18.8022 18.0296 18.5515 18.1195C18.3008 18.2094 18.0264 18.2074 17.777 18.114L16.56 17.658C16.205 17.525 15.81 17.586 15.484 17.782C15.4115 17.8261 15.3381 17.8688 15.264 17.91C14.933 18.093 14.683 18.405 14.62 18.779L14.407 20.059C14.317 20.602 13.847 21 13.297 21H10.703C10.153 21 9.68299 20.602 9.59299 20.06L9.37999 18.779C9.31799 18.405 9.06799 18.093 8.73599 17.909C8.66184 17.8681 8.58849 17.8258 8.51599 17.782C8.19099 17.586 7.79599 17.525 7.43999 17.658L6.22299 18.114C5.97372 18.2075 5.69936 18.2096 5.44869 18.1199C5.19803 18.0302 4.9873 17.8545 4.85399 17.624L3.55699 15.377C3.42394 15.1463 3.37704 14.8759 3.42465 14.6138C3.47226 14.3518 3.61128 14.1152 3.81699 13.946L4.82099 13.119C5.11299 12.879 5.25799 12.506 5.25099 12.127C5.24943 12.042 5.24943 11.957 5.25099 11.872C5.25799 11.494 5.11299 11.122 4.82099 10.882L3.81699 10.054C3.61153 9.88489 3.47267 9.64843 3.42506 9.38662C3.37746 9.12481 3.42421 8.8546 3.55699 8.624L4.85399 6.377C4.98718 6.14614 5.198 5.97006 5.4489 5.88014C5.6998 5.79021 5.97448 5.79229 6.22399 5.886L7.43999 6.342C7.79599 6.475 8.19099 6.414 8.51599 6.218C8.58799 6.174 8.66199 6.131 8.73599 6.09C9.06799 5.907 9.31799 5.595 9.37999 5.221L9.59399 3.94Z"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M15 12C15 12.7956 14.6839 13.5587 14.1213 14.1213C13.5587 14.6839 12.7956 15 12 15C11.2044 15 10.4413 14.6839 9.87868 14.1213C9.31607 13.5587 9 12.7956 9 12C9 11.2044 9.31607 10.4413 9.87868 9.87868C10.4413 9.31607 11.2044 9 12 9C12.7956 9 13.5587 9.31607 14.1213 9.87868C14.6839 10.4413 15 11.2044 15 12Z"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconContainer>
  );
}

export default SettingsIcon;
